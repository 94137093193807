import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    ${
      ""
      /* background: #94d0ff; // light blue vaporwave color
       */
    }
     background: linear-gradient(45deg, #00F0FF, #AA00FF);

  }
`

const Page = styled.div`
  color: #ff48c4; // vaporwave pink color
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  font-size: 1rem;
  a {
    color: inherit; // make link color same as surrounding text
    text-decoration: underline; // optional, if you want links underlined
  }
`

const ContentContainer = styled.div`
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
  width: 90%; // responsive width
  @media (max-width: 768px) {
    margin: 0 1rem;
  }
  background: rgba(
    0,
    0,
    0,
    0.5
  ); // adjust the last value (0.5) to increase or decrease transparency
`

export default function Home() {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tranquility Dev | Custom Software</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://www.tranquility.dev" />
      </Helmet>
      <Page>
        <ContentContainer>
          <p>
            Cyberspace. A consensual hallucination experienced daily by billions
            of legitimate operators, in every nation, by children being taught
            mathematical concepts... A graphic representation of data abstracted
            from banks of every computer in the human system. Unthinkable
            complexity. Lines of light ranged in the nonspace of the mind,
            clusters and constellations of data. Like city lights, receding...
          </p>
          <p>-- William Gibson</p>
          {/* <p>✨✨✨✨</p> */}
          <p>
            Contact:{" "}
            <a href="mailto:hello@tranquility.dev">hello@tranquility.dev</a>.
          </p>
        </ContentContainer>
      </Page>
    </>
  )
}
